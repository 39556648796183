<template>
	<div class="steppers">
		<v-stepper ref="stepper" :steps="steps" v-model="step"></v-stepper>

		<section class="body-steps">
			<div v-if="step == 1" class="container-fluid">
				<div class="row">
					<h2>Sub asignación</h2>
				</div>
				<div class="row mb-4">
					<div class="col-sm-12">Por favor, selecciona la notaria que llevará acabo el proceso de formalización</div>
				</div>
				<div class="row mb-4">
					<div class="col-sm-12">
						<select v-model="oficina" name="subasignacion" id="subasignacion" class="form-control">
							<option v-for="tmpOffice in oficinas" :key="tmpOffice.id" :value="tmpOffice.id">{{ tmpOffice.nombre }}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-3 offset-sm-9">
						<button type="button" @click="definir_oficina()" class="btn principal-btn">Continuar</button>
					</div>
				</div>
			</div>
			<div v-if="step == 2" class="">
				<h2>Documentación básica</h2>
				<div>Deacuerdo a la documentación proporcionada en la opción de "Ver documentos" en el botón superior derecho, señala si es "No viable" o "Viable". De ser viable te enviará al siguiente paso. </div>
				<br />
				<br />
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button type="button" class="btn complementary-btn" @click="ver_noviable=true">No viable</button>
					</div>
					<div class="col-sm-3">
						<button type="button" @click="avanzar_pasos('documentacion_basica')" class="btn principal-btn">Viable</button>
					</div>
				</div>
			</div>
			<div v-if="step == 3">
				<h2>Solicitud CLG</h2>
				<p>Indica si ya se a solicitado el CLG</p>
				<div class="container-fluid">
					<div class="row form-group">
						<div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" @click="avanzar_pasos('solicitud_clg')">Solicitud CLG enviada</button></div>
					</div>
				</div>
			</div>
			<div v-if="step == 4" class="">
				<form @submit.prevent="cargar_clg">
					<h2>Carga de CLG</h2>
					<div>
						<p>Agrega el CLG a la solicitud</p>
						<div class="container-fluid" style="margin-top: 40px; margin-bottom: 40px;">
							<div class="row form-group">
								<label for="carga_clg" class="col-form-label col-sm-2 col-md-1">CLG</label>
								<div class="col-sm-10 col-md-11">
									<input ref="archivo_clg" type="file" name="carga_clg" id="carga_clg" class="form-control">
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="row">
						<div class="col-sm-12 d-flex justify-content-center">
							<div class="col-sm-3"><button class="secondary-btn">Cargar</button></div>
							<div class="col-sm-3">
								<button type="button" class="btn complementary-btn" @click="ver_noviable=true">No viable</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div v-if="step == 5" class="">
				<h2>Documentación completa</h2>
				<br />
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-center">
						<div class="col-sm-3"><button type="button" class="secondary-btn" @click="avanzar_pasos('documentacion_completa')">Documentación completa</button></div>
						<div class="col-sm-3">
							<button type="button" class="btn complementary-btn" @click="ver_noviable=true">No viable</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="step == 6" class="">
				<h2>Firma</h2>
				<div>Aquí podrás programar tu fecha de firma del contrato</div>

				<Calendar :options="{agregar_eventos: agregar_eventos, agregar_invitados: false, invitados: invitados, title: evento.title, descript: evento.descript, location: evento.location}" :eventos="eventos" @nEventos="nuevos_eventos"/>

			</div>
			<div v-if="step == 7" class="text-center">
				<h2>El proceso ah concluido con éxito.</h2>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-center">
						<div class="col-sm-3">
							<button type="button" class="btn principal-btn" @click="$router.back()">Finalizar</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="block"></div>

		<Modal v-if="ver_noviable" :options="{width: '40vw'}" @close="cancelar_noviable">
			<div class="title">No viable</div>
			<div class="body">
				<div class="row form-group">
					<label for="razones" class="col-form-label col-sm-2">Razones por la cual no es viable</label>
					<div class="col-sm-10">
						<textarea v-model="noviable" name="razones" id="razones" cols="30" rows="10" class="form-control"></textarea>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8">
						<button class="btn principal-btn mr-2" @click="agregar_noviable">Continuar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn complementary-btn" @click="cancelar_noviable">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import Modal from '@/components/Modal'
	import Calendar from '@/components/Calendar'

	import api from '@/apps/formalizacion/api/v1/formalizacion'

	import { VStepper } from 'vue-stepper-component'
	import { Datetime } from 'vue-datetime'

	import 'vue-datetime/dist/vue-datetime.css'

	import appconfig from '@/apps/formalizacion/config'

	export default {
		components: {
			VStepper,
			datetime: Datetime,
			Modal,
			Calendar
		},
		data: () => ({
			steps: 8,
			step: 1,
			fecha: null,
			ver_noviable: false,
			noviable: null,
			oficinas: [],
			oficina: null,
			invitados: [],
			eventos: [],
			eventos_nuevos: [],
			evento: {
				title: null
				,descript: null
				,location: null
			}
			,oficina: null
			,isAllLoad: 0
			,agregar_eventos: true
			,documentos: {
				valuacion: 'na'
				,slg: 'na'
			}
			,config: {}
		})
		,props: {
			solicitud: {
				type: Object
				,default: () => ({
					documentacion_basica: null,
					evaluacion: null,
					documentacion_completa: null,
					firma: null
				})
			}
		}
		,mounted: function() {
			this.config = appconfig[this.$helper.getEnv()];

			this.obtener_oficinas();
			this.obtenemos_eventos();
		}
		,methods: {
			obtener_oficinas: async function() {
				let usuario = (await api.mi_usuario()).data;
				// this.$log.info('usuario', usuario);
				this.oficinas = (await api.obtener_empresas_oficinas(usuario.empresa.id)).data;
				this.oficina = this.oficinas[0].id;
				this.isAllLoad++;

				this.invitados = [
					{
						name: this.$auth.getUser().nombre
						,email: this.$auth.getUser().email
					}
					,{
						name: this.solicitud.nombre
						,email: this.solicitud.email
					}
					,...this.config.attendees
				]

				this.$log.info('invitados', this.invitados)
			}
			,obtenemos_eventos: async function() {
				try {
					this.eventos = (await api.mis_eventos()).data;

					for(let i=0; i<this.eventos.length; i++) {
						this.eventos[i].attendees = this.eventos[i].invitados;
						delete this.eventos[i].invitados;
					}

					this.$log.info('eventosAgrear: ', this.eventos);
				}catch(e) {
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_oficina: async function() {
				this.oficina = (await api.obtener_oficina(this.solicitud.oficina_id)).data
			}
			,visitar_pasos: function(step) {
				let steps = document.querySelectorAll('.v-step');

				this.step = step;
				step = step-1;

				let parent = this;
				setTimeout(function() {
					for(let i=0; i<step; i++) {
						// parent.$log.info('step: ', steps[i]);
						steps[i].classList.add('is-visited');

						// steps[i].setAttribute('data-prueba','prueba');
					}
				},200);
			}
			,programar_cita: async function() {
				// $refs.stepper.next()

				if (this.fecha) {
					let evento = (await api.crear_cita(this.solicitud.solicitud, {inicio: this.fecha})).data;
					// this.$log.info('evento: ', evento);

					if (evento.success) {
						// this.$helper.showMessage('Evento creado','El evento se acreado correctamente y se han enviado las invitaciones pertinentes.', 'success', 'notifications');
						this.$refs.stepper.next();
					}else
						this.$helper.showMessage('Error',evento.message, 'error', 'alert');
				}else
					this.$helper.showMessage('Error','Debes seleccionar una fecha para agendar la cita', 'error', 'alert');
			}
			,formato_fecha: function(date) {
				return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
			}
			,cancelar_noviable: function() {
				this.noviable = null;
				this.ver_noviable = false;
			}
			,agregar_noviable: async function() {
				this.$log.info('noviable', this.noviable);
				
				if (this.noviable != null) {
					if (this.noviable.length > 5) {
						await api.agregarObservacion(this.solicitud.solicitud, {observacion: 'Solicitud '+this.solicitud.solicitud+' marcada como no viable por "'+this.$auth.getUser().nombre+'", entra en modo pausa'});
						await api.agregarObservacion(this.solicitud.solicitud, {razon: this.noviable});
						let razon = {
							razon: this.noviable
						}
						await api.noviable(this.solicitud.solicitud,razon);

						this.cancelar_noviable();
						this.$router.push({name: 'formalizacion_notarios'});
					}else
					this.$helper.showMessage('Error','La razón debe tener mínimo 5 caracteres', 'error', 'alert');
				}else
				this.$helper.showMessage('Error','Tienes que indicar la razón por la cual estas marcando la solicitud como no viable', 'error', 'alert');
			}
			,definir_oficina: async function() {
				try {
					this.$log.info('definiendo oficinas', this.solicitud);

					if (!this.solicitud.solicitud)
						return this.$log.error('No esta definida la solicitud');

					await api.actualizar_oficina(this.solicitud.solicitud, this.oficina);

					if (this.oficinas.length == 1)
						this.$helper.showMessage('Oficina asignada','La oficina se asigno de forma automatica', 'success', 'notifications');

					this.$refs.stepper.next();
				}catch(e) {
					this.$log.error('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,nuevos_eventos: async function(eventos) {
				this.$log.info('eventosNuevos',eventos);
				let newEvents = [];
				let errores = [];


				for(let i=0; i<eventos.length; i++) {
					if (typeof(eventos[i].id) == 'undefined')
						try {
							eventos[i].event = 'firma';
							await api.crear_cita(this.solicitud.solicitud, eventos[i]);
						}catch(e) {
							errores.push({evento: eventos[i].title, error: e.getMessage()});
						}
				}

				if (errores.length == 0) {
					this.$helper.showMessage('Evento creado','El evento se a creado correctamente, se a enviado los correos de notificación a los invitados designados', 'success', 'notifications');

					this.$refs.stepper.next();

					this.agregar_eventos = false;
				}else {
					let mssg = 'Se encontraron los siguientes errores: <ul>';

					let errorHash = [];
					for(let i=0; i<errores.length; i++) {
						errorHash.push(errores[i].hash);
						mssg += '<li>'+errores[i].evento+': '+errores[i].error+'</li>';
					}
					mssg += '</ul>';

					let tmpEvents = [];
					eventos.forEach((evento) => {
						if (evento.hash && errorHash.indexOf(evento.hash) == -1)
							tmpEvents.push(evento);
					});

					this.eventos = tmpEvents;
					this.$helper.showMessage('Error', mssg, 'error', 'alert');
				}
			}
			,checkStep: function(step) {
				if (this.solicitud) {
					let steps = [];

					if (this.solicitud.sla)
						this.solicitud.sla.forEach(sla => {
							steps.push(sla.tipo);
						});

					this.$log.info('step', step, steps.indexOf(step) != -1);
					let numStep = steps.indexOf(step) != -1;
					this.$log.info('numStep', numStep);

					return numStep;
				}else {
					return false;
				}
			}
			,avanzar_pasos: async function(step) {
				try {
					await api.actualizar_fase(this.solicitud.solicitud, step);

					this.$refs.stepper.next();
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cargar_clg: async function() {
				try {
					let clg = this.$refs.archivo_clg.files;

					if (clg.length == 0)
						return this.$helper.showMessage('Error','Debes cargar el documento CLG','error','alert');

					let formData = new FormData();
					formData.append('documento',clg[0]);
					formData.append('tag','CLG');
					formData.append('origen','notario');

					await api.subir_documento(this.solicitud.solicitud, formData);

					this.avanzar_pasos('cargar_clg');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
		}
		,watch: {
			solicitud: {
				handler: function(val, oldVal) {
					// this.$log.info('solicitud: ', this.solicitud);
					let step = 1;
					if (this.checkStep('firma'))
						step = 7;
					else if (this.checkStep('documentacion_completa'))
						step = 6;
					else if (this.checkStep('cargar_clg'))
						step = 5;
					else if(this.checkStep('solicitud_clg'))
						step = 4;
					else if (this.checkStep('documentacion_basica'))
						step = 3;
					else if (this.checkStep('subasignacion'))
						step = 2;

					this.$log.info('finalStep', step);
					this.visitar_pasos(step);

					this.agregar_eventos = !this.checkStep('firma');

					this.evento.title = 'Firma de solicitud '+this.solicitud.solicitud;
					this.evento.descript = 'Evento para llevar a cabo la firma de contrato para la solicitud '+this.solicitud.solicitud;
					this.isAllLoad++;
				}
				,deep: true
			}
			,oficinas: {
				handler: function() {
					for(let i=0; i<this.oficinas.length; i++) {
						if (this.oficinas[i].id == this.solicitud.oficina_id) {
							this.evento.location = this.oficinas[i].direccion;
							i = this.oficinas.length;
						}
					}

					if (this.oficinas.length > 0) {
						if (this.oficinas.length == 1 && !this.checkStep('subasignacion')) {
							this.oficina = this.oficinas[0].id;
							// this.$log.info('oficinas actualizadas', this.oficinas);
							this.definir_oficina();
						}
					}
				}
				,deep: true
			}
			,step: function(val) {
				this.$log.info('step',val);
			}
		}
	}
</script>

<style lang="scss">
	.block {
		width: 100%;
		height: 150px;
		position: absolute;
		top: 19px;
		left: 32px;
		z-index:100;
	}
	.is-visited,
	.is-active {
		label {
			span.index {
				background-color: #3CA63E !important;
				border: 1px solid #3CA63E !important;
				color: #fff !important;
				width: 5rem;
				height: 5rem;
				font-size: 2rem !important;
			}
		}
	}

	.steppers {
		padding: 3rem;
		margin: auto 6rem;
		position: relative;

		.body-steps {
			padding: 1rem;

			.text-center {
				h2 {
					padding-bottom: 2rem;
				}
			}


			.theme-orange .vdatetime-popup__header,
			.theme-orange .vdatetime-calendar__month__day--selected > span > span,
			.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
				background: #24B361;
			}

			.theme-orange .vdatetime-year-picker__item--selected,
			.theme-orange .vdatetime-time-picker__item--selected,
			.theme-orange .vdatetime-popup__actions__button {
				color: #0781cd;
			}
		}
		label {
			span.index {
				width: 5rem;
				height: 5rem;
				font-size: 1.5rem;
			}
		}

		.flotante-btn {
			width: 140px;
			position: absolute;
			top: 221px;
			right: 65px;
		}
	}

	@media (max-width: 1280px) {
		.steppers {
			margin: 0px !important;
		}
	}
</style>